<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <base-material-card
          color="primary"
          icon="mdi-database-eye-outline"
          inline
          class="px-5 py-3"
        >
          <template v-slot:after-heading>
            <div class="display-2 font-weight-light">
              Painel Unificado INDQUAL
            </div>
          </template>
          <v-row>
            <v-col cols="12">
              <router-link
                style="float: right !important"
                to="/geracao-bdgd/validacoes-bdgd/painel-unificado"
              >
                Painel Unificado de Validações
              </router-link>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <grafico-spider-web-total-conjuntos />
            </v-col>
            <v-col cols="6">
              <grafico-spider-web-conjuntos-aprovados />
            </v-col>
          </v-row>
          <v-progress-circular
            v-if="loadingResumoValidacoes"
            size="20"
            indeterminate
            color="rgba(0, 0, 0, 0.87)"
          />
          <v-row v-else>
            <v-col cols="12">
              <v-tabs
                vertical
                v-model="tabResumoValidacoes"
              >
                <v-tab
                  v-for="(resumo, i) in resumoValidacoes"
                  :key="i"
                  :value="i"
                >
                  {{ resumo.empresa }}
                </v-tab>
                <v-tab-item
                  flat
                  v-for="(resumo, i) in resumoValidacoes"
                  :key="i"
                  :value="i"
                >
                  <v-row>
                    <v-col cols="12">
                      <div
                        class="text-center display-1 font-weight-normal mb-6"
                      >
                        {{ resumo.empresa }} :: resumo das validações da BDGD
                        {{ resumo.mes_dados | formatToMonth }} v{{
                          resumo.bdgd_versao
                        }}
                      </div>
                      <tabela-resumo-validacoes
                        :metodologiasAneelAtivas="metodologiasAneelAtivas"
                        :resumoValidacoes="
                          getResumoValidacoesPorEmpresa(resumo)
                        "
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <grafico-simulacoes-bdgd-indqual
                        :metodologiasAneelAtivas="metodologiasAneelAtivas"
                        :resumoValidacoes="
                          getResumoValidacoesPorEmpresa(resumo)
                        "
                      />
                    </v-col>
                  </v-row>
                  <v-row
                    v-for="(
                      resumoStatusConjuntos, j
                    ) in getResumoStatusConjuntosPorEmpresa(resumo.empresa)"
                    :key="`${resumo.empresa}-${j}`"
                  >
                    <v-col cols="12">
                      <resumo-status-conjuntos-por-empresa
                        :empresa="resumoStatusConjuntos.empresa"
                        :bdgdVersao="{
                          id: resumoStatusConjuntos.id,
                          mes_dados: resumoStatusConjuntos.mes_dados,
                          versao: resumoStatusConjuntos.bdgd_versao
                        }"
                        :metodologiaAneel="{
                          codigo: resumoStatusConjuntos.codigo_metodologia,
                          nome: resumoStatusConjuntos.nome_metodologia
                        }"
                        :indices="resumoStatusConjuntos.indices"
                        :resumo="JSON.parse(resumoStatusConjuntos.dados_resumo)"
                      />
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ValidacoesIndqualService from '@/services/ValidacoesIndqualService';
import GraficoSpiderWebTotalConjuntos from '@/components/geracao-bdgd/indqual/GraficoSpiderWebTotalConjuntos';
import GraficoSpiderWebConjuntosAprovados from '@/components/geracao-bdgd/indqual/GraficoSpiderWebConjuntosAprovados';
import GraficoSimulacoesBdgdIndqual from '@/components/geracao-bdgd/indqual/GraficoSimulacoesBdgdIndqual.vue';
import TabelaResumoValidacoes from '@/components/geracao-bdgd/indqual/TabelaResumoValidacoes.vue';
import ResumoStatusConjuntosPorEmpresa from '@/components/geracao-bdgd/indqual/ResumoStatusConjuntosPorEmpresa.vue';

export default {
  name: 'PainelUnificadoIndqual',
  components: {
    GraficoSpiderWebTotalConjuntos,
    GraficoSpiderWebConjuntosAprovados,
    GraficoSimulacoesBdgdIndqual,
    TabelaResumoValidacoes,
    ResumoStatusConjuntosPorEmpresa
  },
  data: () => ({
    tabResumoValidacoes: null,
    loadingResumoValidacoes: false,
    loadingResumoStatusConjuntosPorMetodologia: false,
    metodologiasAneelAtivas: [],
    resumoValidacoes: [],
    resumoStatusConjuntosPorMetodologia: []
  }),
  mounted() {
    this.getMetodologiasAneelAtivas();
    this.getResumoValidacoes();
    this.getResumoStatusConjuntosPorMetodologia();
  },
  methods: {
    getMetodologiasAneelAtivas() {
      ValidacoesIndqualService.getMetodologiasAneelAtivas()
        .then((response) => {
          this.metodologiasAneelAtivas = response.data;
        })
        .catch((err) => {
          console.log('Erro: ', err);
          this.$toast.error(
            'Erro ao buscar as metodologias ANEEL ativas no sistema.',
            '',
            {
              position: 'topRight'
            }
          );
        });
    },
    getResumoValidacoes() {
      this.loadingResumoValidacoes = true;
      ValidacoesIndqualService.getResumoValidacoesPainelUnificado()
        .then(({ data }) => (this.resumoValidacoes = data))
        .catch((err) => {
          console.log('Erro: ', err);
          this.$toast.error(
            'Erro ao buscar o resumo das validações executadas.',
            '',
            { position: 'topRight' }
          );
        })
        .finally(() => (this.loadingResumoValidacoes = false));
    },
    getResumoStatusConjuntosPorMetodologia() {
      this.loadingResumoStatusConjuntosPorMetodologia = true;
      ValidacoesIndqualService.getResumoStatusConjuntosPorMetodologiaPainelUnificado()
        .then(({ data }) => (this.resumoStatusConjuntosPorMetodologia = data))
        .catch((err) => {
          console.log('Erro: ', err);
          this.$toast.error(
            'Erro ao buscar o resumo dos status de validações dos conjuntos elétricos.',
            '',
            { position: 'topRight' }
          );
        })
        .finally(
          () => (this.loadingResumoStatusConjuntosPorMetodologia = false)
        );
    },
    getResumoValidacoesPorEmpresa(resumo) {
      return JSON.parse(resumo.dados_resumo);
    },
    getResumoStatusConjuntosPorEmpresa(empresa) {
      return this.resumoStatusConjuntosPorMetodologia.filter(
        (linha) => linha.empresa === empresa
      );
    }
  }
};
</script>
