<template>
  <v-container fluid>
    <v-progress-circular
      v-if="loading"
      size="20"
      indeterminate
      color="rgba(0, 0, 0, 0.87)"
    />
    <highcharts
      v-else
      :options="chartOptions"
    />
  </v-container>
</template>

<script>
import ValidacoesIndqualService from '@/services/ValidacoesIndqualService';
export default {
  name: 'GraficoSpiderWebTotalConjuntos',
  data: () => ({
    loading: false,
    resumo: []
  }),
  mounted() {
    this.getResumoConjuntosAprovados();
  },
  methods: {
    getResumoConjuntosAprovados() {
      this.loading = true;
      ValidacoesIndqualService.getResumoConjuntosAprovados()
        .then(({ data }) => (this.resumo = data))
        .catch((err) => {
          console.log('Erro: ', err);
          this.$toast.error(
            'Erro ao buscar o resumo dos conjuntos elétricos aprovados.',
            '',
            { position: 'topRight' }
          );
        })
        .finally(() => (this.loading = false));
    }
  },
  computed: {
    categories() {
      return this.resumo.map((linha) => linha.empresa);
    },
    seriesData() {
      return this.resumo.map((linha) => Number(linha.total_conj));
    },
    chartOptions() {
      const seriesData = this.seriesData;
      return {
        chart: {
          polar: true,
          type: 'line'
        },
        title: {
          text: 'CONJ',
          x: -200
        },
        xAxis: {
          categories: this.categories,
          tickmarkPlacement: 'on',
          lineWidth: 0,
          gridLineColor: '#BDBDBD',
          labels: {
            style: {
              color: '#000'
            },
            formatter: function () {
              return `${this.value}: ${seriesData[this.pos]}`;
            }
          }
        },
        yAxis: {
          gridLineInterpolation: 'polygon',
          lineWidth: 0,
          min: 0,
          gridLineColor: '#BDBDBD'
        },
        tooltip: {
          shared: true,
          pointFormat:
            '<span style="color:{series.color}">{series.name}: <b>{point.y:,.0f}</b><br/>'
        },
        legend: false,
        series: [
          {
            name: 'CONJ',
            color: '#1565C0',
            data: this.seriesData,
            pointPlacement: 'on'
          }
        ]
      };
    }
  }
};
</script>
