<template>
  <div>
    <v-row>
      <v-col cols="12">
        <div class="text-center display-1 font-weight-normal mb-6">
          {{ empresa }} :: {{ metodologiaAneel.nome }} :: BDGD
          {{ bdgdVersao.mes_dados | formatToMonth }} v{{ bdgdVersao.versao }} ::
          {{ indicesAnalisados }}
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <div
          class="mb-2"
          :style="{ color: qtdConjuntosComErros === 0 ? 'green' : '#e91e63' }"
        >
          Qtd de conjuntos com erros:
          {{ qtdConjuntosComErros | parseNumberToIntegerBR }}
        </div>
        <div
          class="mb-2"
          :style="{ color: qtdConjuntosOk > 0 ? 'green' : '#e91e63' }"
        >
          Qtd de conjuntos ok:
          {{ qtdConjuntosOk | parseNumberToIntegerBR }}
        </div>
        <div
          class="mb-2"
          :style="{ color: qtdConjuntos > 0 ? 'green' : '#e91e63' }"
        >
          Qtd de conjuntos:
          {{ qtdConjuntos | parseNumberToIntegerBR }}
        </div>
      </v-col>
      <v-col
        cols="9"
        style="max-width: 1200px"
      >
        <tabela-resultado-validacao-dec-fec
          v-if="indicesAnalisados === 'DEC/FEC'"
          :resultadoValidacao="resumo"
        />
        <tabela-resultado-validacao-nuc
          v-else
          :resultadoValidacao="resumo"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TabelaResultadoValidacaoDecFec from '@/components/geracao-bdgd/indqual/TabelaResultadoValidacaoDecFec.vue';
import TabelaResultadoValidacaoNuc from '@/components/geracao-bdgd/indqual/TabelaResultadoValidacaoNuc.vue';

export default {
  name: 'ResumoStatusConjuntosPorEmpresa',
  components: {
    TabelaResultadoValidacaoDecFec,
    TabelaResultadoValidacaoNuc
  },
  props: {
    empresa: {
      type: String,
      required: true
    },
    bdgdVersao: {
      type: Object,
      required: true
    },
    metodologiaAneel: {
      type: Object,
      required: true
    },
    indices: {
      type: String,
      required: true
    },
    resumo: {
      type: Object,
      required: true
    }
  },
  computed: {
    indicesAnalisados() {
      if (this.indices === 'DEC_FEC') return 'DEC/FEC';
      return this.indices;
    },
    qtdConjuntos() {
      return this.resumo &&
        this.resumo['resumo'] &&
        this.resumo['resumo']['qtd_conjuntos']
        ? this.resumo['resumo']['qtd_conjuntos']
        : 0;
    },
    qtdConjuntosOk() {
      return this.resumo &&
        this.resumo['resumo'] &&
        this.resumo['resumo']['qtd_conjuntos_ok']
        ? this.resumo['resumo']['qtd_conjuntos_ok']
        : 0;
    },
    qtdConjuntosComErros() {
      return this.resumo &&
        this.resumo['resumo'] &&
        this.resumo['resumo']['qtd_conjuntos_erros']
        ? this.resumo['resumo']['qtd_conjuntos_erros']
        : 0;
    }
  }
};
</script>
